import { PaletteOptions } from '@mui/material/styles'

// Configure all the required colors palettes here
export const lightThemePalette: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#A62159',
    contrastText: '#FDFDFD',
    light: '#EEDFEB',
  },
  secondary: {
    main: '#089CC1',
    light: '#E2EEF3',
  },
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  success: {
    main: '#00AA63',
  },
  error: {
    main: '#FF5353',
  },
  custom: {
    main: '#f5f5f5',
    thumb: '#bbbec2',
    track: '#e8e9eb',
    grey: '#607176',
    bluishBlack: '#033B49',
    lightPink: '#FCF3F3',
    lightGrey: '#F2F2F2',
    lightBlack: '#373234',
    dullBlack: '#7B7B7D',
    silver: '#C2C2C2',
    green: '#033B49',
    darkWhite: '#EEEEEE',
    darkSilver: '#272727',
    darkGrey: '#DEDEDE',
    lightYellow: '#FFF0D6',
    lightSilver: '#666668',
    lightGreen: '#06D001',
    skyBlue: '#1679AB',
    lightWhite: '#EBEBEB',
    greyish: {
      '100': '#EAECF0',
      '300': '#C7C7C9',
      '400': '#777777',
      '500': '#E1E1E2',
    },
    darkPink: '#F5E4EC',
    dullPink: '#A81E5E33',
    slightGrey: '#D6D6D6',
    lightMaroon: '#E3C8D3',
    lightGreyish: '#ABB7BA',
    lightestYellow: '#FFCA6766',
  },
}
