'use client'

import { ReactNode } from 'react'
import { Box } from '@mui/material'
import { useGeolocation } from '@hooks'
import { BackDropLoader } from '@components/common'

interface CheckGeoLocationProps {
  children: ReactNode
}

export function CheckGeoLocation({ children }: CheckGeoLocationProps) {
  const { isLoading } = useGeolocation()

  return (
    <Box>
      <BackDropLoader open={isLoading} />
      {children}
    </Box>
  )
}
